/**
 *
 * CheckboxIcon
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

export const CheckboxIcon = ({
  checked = true,
  fill,
  width = '100%',
  height = '100%',
  style = {},
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    height={height}
    width={width}
    style={style}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1"
      viewBox="0 0 100 100"
      transform="matrix(.69 0 0 .69 15.5 15.5)"
    >
      <title>Checkbox</title>
      <path
        fill={fill}
        d="M84.945 5.414H14.768c-5.46 0-9.9 4.441-9.9 9.9v70.177c0 5.46 4.44 9.9 9.9 9.9h70.177c5.459 0 9.9-4.44 9.9-9.9V15.314c0-5.459-4.441-9.9-9.9-9.9zm4.458 80.077c0 2.459-2 4.459-4.458 4.459H14.768c-2.459 0-4.459-2-4.459-4.459V15.314c0-2.458 2-4.458 4.459-4.458h70.177c2.458 0 4.458 2 4.458 4.458v70.177z"
      />
      {checked && (
        <path
          fill={fill}
          d="M79.645 23.3l-41.88 41.879L20.499 47.91a3.216 3.216 0 0 0-4.547 4.548L35.492 72c.628.628 1.45.942 2.274.942s1.646-.314 2.274-.942l44.153-44.153a3.216 3.216 0 0 0-4.548-4.547z"
        />
      )}
    </svg>
  </svg>
);

CheckboxIcon.propTypes = {
  checked: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  fill: PropTypes.string,
};

export default CheckboxIcon;
