/**
 *
 * Validators for LoginFormik
 *
 *
 */

export default values => {
  const errors = {};

  switch (true) {
    case !values.firstName:
      errors.firstName = 'Required';
      break;
    case !values.lastName:
      errors.lastName = 'Required';
      break;
    case !values.email:
      errors.email = 'Required';
      break;
    case !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email):
      errors.email = 'Email not valid';
      break;
    case !values.password:
      errors.password = 'Required';
      break;
    case !values.confirmPass:
      errors.confirmPass = 'Required';
      break;
    case values.password !== values.confirmPass:
      errors.confirmPass = 'Passwords must match.';
      break;
    case !values.terms:
      errors.terms = 'Please read and confirm our terms and conditions.';
      break;
    default:
  }

  return errors;
};
