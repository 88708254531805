/**
 *
 * Login Logic handler
 *
 *
 */

import jwtDecode from 'jwt-decode';

import { loginComplete } from 'actions/auth';

import { login as loginHTTP } from 'utils/api/auth';
import { getUserById } from 'utils/api/users';

import { toastSuccess, toastWarn, toastError } from 'utils/toastify';
import { ACCOUNT_ROUTE } from 'utils/routes';

import { sendCustomGaEvent } from 'utils/ga';

export const ERROR_MSG = 'Sorry, something went wrong. Please try again.';
export const userIsNotConfirmedMsg =
  'Account is not confirmed yet! Please check your email';

export const handleLoginLogic = ({
  setServerError,
  closeModal,
  navigate,
  dispatch,
}) => async (values, { setSubmitting, setFieldError }) => {
  if (values.rememberMe) {
    // in case user clicks rememberMe before values.email is populated
    localStorage.setItem('rememberMe', values.email);
  }

  let loginRes;
  // attempt login to API
  try {
    loginRes = await loginHTTP({
      username: values.email,
      password: values.password,
    });
  } catch (loginErr) {
    setSubmitting(false);
    toastError('Sorry, something went wrong with login. Please try again.');
    if (
      (loginErr.code && loginErr.code === 401) ||
      (loginErr.status && loginErr.status === 401)
    ) {
      setFieldError('password', loginErr.message);
    }
    // need to return from login trycatch block to block execution of code below
    return;
  }

  // decode token and try to fetch user data
  try {
    const {
      data: { token },
    } = loginRes;

    const decoded = jwtDecode(token);
    // fetch current user data to check current confirmation status
    const userData = await getUserById(decoded.user.id);

    /** *****************  ADD CODE BACK FOR STAGING PUSH ^^^ *****************  */

    if ('isConfirmed' in userData && !userData.isConfirmed) {
      // new user, account not yet confirmed via email
      setSubmitting(false);
      // toastWarn(userIsNotConfirmedMsg);
      setServerError(userIsNotConfirmedMsg + ' as well as your spam folder.');
    } else {
      // normal user
      await dispatch(loginComplete(token));
      //navigate(ACCOUNT_ROUTE);
      toastSuccess('Successfully logged in!');
      setSubmitting(false);
      closeModal && closeModal();
      sendCustomGaEvent('Log In', 'Success', 'Login Successful');
    }
    /** *****************  ADD CODE BACK FOR STAGING PUSH ^^^ *****************  */

    /** ***************** REMOVE BEFORE STAGING ^^^ *****************  */
    // normal user
    // await dispatch(loginComplete(token));
    // navigate(ACCOUNT_ROUTE);
    // toastSuccess('Successfully logged in!');
    // setSubmitting(false);
    // closeModal && closeModal();
    /** ***************** REMOVE BEFORE STAGING ^^^ *****************  */
  } catch (err) {
    setSubmitting(false);
    const msg =
      err.message || ('toString' in err && err.toString()) || ERROR_MSG;
    toastError(msg);
    if ((err.code && err.code === 401) || (err.status && err.status === 401)) {
      setFieldError('password', err.message);
    }
  }
};
