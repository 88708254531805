/**
 *
 *  RegistrationFormik
 *
 *
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import Spinner from 'components/Spinner';
import Checkbox from 'components/Elements/Checkbox';
import Button from 'components/Elements/Button';
import Link from 'components/Elements/Link';
import {
  FormWrapper,
  StyledField,
  StyledError,
  ExtraAlt,
} from 'components/lib/Formik';

// import { SIGNUP_PAGE_RE_CAPTCHA } from 'utils/constants';

import validate from './validators';

const RegistrationFormik = ({
  initialValues,
  onSubmit,
  handleRedirect,
  setTempData,
  getTempData,
}) => {
  const [state, setState] = useState({});

  const handleChange = (field, setFieldValue) => evt => {
    const { value } = evt.target;
    setFieldValue(field, value);
    setTempData &&
      setTempData('signup', {
        [field]: value,
      });
  };

  // const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (getTempData) {
      const tempData = getTempData();
      setState(tempData.signup);
    }
  }, [getTempData]);

  useEffect(() => {
    (async () => {
      // const recaptchaTokenV1 = await executeRecaptcha(SIGNUP_PAGE_RE_CAPTCHA);
      //
    })();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        ...state,
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        (async () => {
          // const recaptchaTokenV2 = await executeRecaptcha(
          //   SIGNUP_PAGE_RE_CAPTCHA,
          // );

          //
          onSubmit(values, { setSubmitting, setFieldError });
        })();
      }}
    >
      {({
        isSubmitting,
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        // eslint-disable-next-line
        ...rest
      }) => {
        return (
          <>
          <ExtraAlt>
            <div>
              <span>Already Have an account?</span>
              <Link secondary to="/sign-in" onClick={handleRedirect}>
                Sign In
              </Link>
            </div>
          </ExtraAlt>
          <Form style={{ position: 'relative' }}>
            <FormWrapper>
              {/* <Spinner loading={isSubmitting} cover /> */}
              <FormFields>
                <StyledField
                  useAnimated
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  touched={touched}
                  errors={errors}
                  onBlur={() => setFieldTouched('firstName', true)}
                  onChange={handleChange('firstName', setFieldValue)}
                  value={values.firstName}
                />
                <StyledField
                  useAnimated
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  touched={touched}
                  errors={errors}
                  onBlur={() => setFieldTouched('lastName', true)}
                  onChange={handleChange('lastName', setFieldValue)}
                  value={values.lastName}
                />
                <StyledField
                  useAnimated
                  type="email"
                  name="email"
                  placeholder="Email"
                  touched={touched}
                  errors={errors}
                  onBlur={() => setFieldTouched('email', true)}
                  onChange={handleChange('email', setFieldValue)}
                  value={values.email}
                />
                <StyledField
                  useAnimated
                  type="password"
                  name="password"
                  placeholder="Password"
                  touched={touched}
                  errors={errors}
                  onBlur={() => setFieldTouched('password', true)}
                  onChange={handleChange('password', setFieldValue)}
                  value={values.password}
                />
                <StyledField
                  useAnimated
                  type="password"
                  name="confirmPass"
                  placeholder="Confirm Password"
                  touched={touched}
                  errors={errors}
                  onBlur={() => setFieldTouched('confirmPass', true)}
                  onChange={handleChange('confirmPass', setFieldValue)}
                  value={values.confirmPass}
                />

                <Field
                  name="terms"
                  fill="grey"
                  component={Checkbox}
                  initialValue={!!values.terms}
                  checked={!!values.terms}
                  onChange={checked => {
                    setFieldValue('terms', checked);
                  }}
                  renderLabel={() => (
                    <StyledLabelSpan>
                      By checking this box you agree to our
                      <Link
                        secondary
                        href="/terms-and-conditions"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                    </StyledLabelSpan>
                  )}
                />
                <StyledError error={errors.terms} />
              </FormFields>

              <StyledError error={errors.server} />
              <StyledNote>
                Before you can log into your account, you must confirm your
                email address by clicking the link we will send you after you
                click "Sign Up."
              </StyledNote>
              <Controls>
                <Button light type="submit" disabled={isSubmitting}>
                  Sign Up
                </Button>
              </Controls>
              <StyledNote>
                If you do not see an email confirmation link in your inbox,
                please check your spam folder. If you still do not see it,
                please contact us at:{' '}
                <a href="mailto:support@ripspot.com">support@ripspot.com</a>
              </StyledNote>
            </FormWrapper>
          </Form>
          </>
        );
      }}
    </Formik>
  );
};

RegistrationFormik.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  setTempData: PropTypes.func,
  getTempData: PropTypes.func,
  handleRedirect: PropTypes.func,
};

export default RegistrationFormik;

const FormFields = styled.div`
  > div {
    margin-bottom: 25px;
  }
`;

const StyledLabelSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: 1rem;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25px;
`;

const StyledNote = styled.p`
  font-size: 1.05rem;
  margin: 1em 0;
  a {
    color: ${({ theme }) => theme.colors.Primary};
    text-decoration: underline;
  }
`;
