/**
 *
 * Checkbox
 *
 */

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import uuid from 'uuid';

import CheckboxIcon from 'components/icons/Checkbox';

// import { FormattedMessage } from 'react-intl';
// import messages from './messages';

const Checkbox = ({
  initialValue,
  onChange,
  style,
  visible = true,
  fill = '#000',
  checkFill = '#fff',
  iconStyles,
  iconWidth,
  label,
  renderLabel,
}) => {
  const [key] = useState(uuid());
  const [checked, setChecked] = useState(initialValue || false);
  // eslint-disable-next-line
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setChecked(initialValue);
    }
  }, [initialValue]);

  const handleChange = e => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    onChange && onChange(isChecked);
  };

  // apply correct label if it exists
  // either renderProp
  // or string
  let LabelCompoennt = null;
  if (renderLabel) {
    LabelCompoennt = renderLabel;
  } else if (label) {
    LabelCompoennt = () => <span>{label}</span>;
  }

  return (
    <Container visible={visible} style={style}>
      <Input
        id={key}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      <Label htmlFor={key}>
        <SVGWrapper checkFill={checkFill}>
          <CheckboxIcon
            checked={checked}
            width={iconWidth || 20}
            height={iconWidth || 20}
            style={{
              ...iconStyles,
            }}
            fill={fill}
          />
        </SVGWrapper>
        {LabelCompoennt && <LabelCompoennt />}
      </Label>
    </Container>
  );
};

Checkbox.propTypes = {
  initialValue: PropTypes.bool,
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  fill: PropTypes.string,
  checkFill: PropTypes.string,
  label: PropTypes.string,
  renderLabel: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  iconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default memo(Checkbox);

const Container = styled.div`
  position: relative;

  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const Input = styled.input`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
`;

const Label = styled.label`
  color: #000;
  display: flex;
  align-items: center;
  font-size: 0.8rem
  width: auto;
  cursor: pointer;
`;

const SVGWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  svg {
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
`;
